<template>
  <div>
    <h4 v-if="isNaN(rubric.id) && show_title">Crear Rúbrica</h4>
    <h4 v-else-if="show_title">Editar Rúbrica</h4>
    <div>
      <b-form>
        <!-- <b-form-group
          label="Nombre"
          label-for="input-name"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-name"
            v-model="$v.rubric.name.$model"
            :state="validateState('name')"
            aria-describedby="input-name-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-name-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label="Descripción"
          label-for="input-description"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-textarea
            id="input-description"
            v-model="$v.rubric.description.$model"
            :state="validateState('description')"
            aria-describedby="input-description-feedback"
            size="sm"
          ></b-form-textarea>
          <b-form-invalid-feedback id="input-description-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group> -->
        <InstrumentForm
          :TestForm="TestForm"
          :Instrument="rubric"
          :evaluation_id="evaluation_id"
          @created="saveRubric"
          @update_file="updateFile"
        >
          <template>
            <b-form-group>
              <b-form-select :options="instrumentTypes"> </b-form-select>
            </b-form-group>
          </template>
        </InstrumentForm>
        <div class="row">
          <div
            v-if="show_delete_button && !isNaN(rubric.id)"
            class="col"
            style="text-align: left"
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="danger"
              @click="deleteRubric"
              >Eliminar</b-button
            >
          </div>
          <!-- <div v-if="show_save_button" class="col" style="text-align: right">
            <b-button class="mr-1" size="sm" @click="saveRubric"
              >Guardar</b-button
            >
          </div> -->
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ObservationInstrumentForm",
  components: {
    InstrumentForm: () => import("../InstrumentForm"),
  },
  mixins: [validationMixin],
  props: {
    Rubric: {
      type: Object,
    },
    TestForm: {
      type: Boolean,
      required: true,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rubric: {
        id: this.Rubric ? this.Rubric.id : generateUniqueId(),
        title: this.Rubric ? this.Rubric.title : "",
        description: this.Rubric ? this.Rubric.description : "",
        evaluation: this.Rubric ? this.Rubric.evaluation : this.evaluation_id,
        score: this.Rubric ? this.Rubric.score : 0,
        sub_type: this.Rubric ? this.Rubric.sub_type : null,
        procedure_evidences: this.Rubric ? this.Rubric.procedure_evidences : [],
        instrument_type: this.Rubric ? this.Rubric.instrument_type : null,
        start_date: this.Rubric ? this.Rubric.start_date : this.$moment(),
        due_date: this.Rubric
          ? this.Rubric.due_date
          : this.$moment().add(2, "hours"),
        publish: this.Rubric ? this.Rubric.publish : false,
        publish_date: this.Rubric ? this.Rubric.publish_date : null,
        allow_access_student: this.Rubric
          ? this.Rubric.allow_access_student
          : false,
        show_attached_before_start_date: this.Instrument
          ? this.Instrument.show_attached_before_start_date
          : false,
        attachments: this.Rubric ? this.Rubric.attachments : [],
      },
    };
  },
  validations: {
    rubric: {
      title: { required },
      description: {},
    },
  },
  computed: {
    ...mapGetters({
      instrumentTypes: names.EVALUATION_INSTRUMENTS,
      evaluations: names.EVALUATIONS,
    }),
    evaluation() {
      if (!this.rubric) return null;
      return this.evaluations.find((x) => x.id == this.rubric.evaluation);
    },
    instrumentType() {
      if (!this.evaluation) return null;
      return this.instrumentTypes.find((x) => x.id == this.evaluation.type);
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.rubric[key];
      return $dirty ? !$error : null;
    },
    saveRubric(rubric) {
      this.rubric = rubric;
      this.$v.rubric.$touch();
      if (this.$v.rubric.$anyError) {
        return;
      }
      if (isNaN(this.rubric.id)) this.createRubric();
      else this.updateRubric();
    },
    updateFile(file) {
      if (!isNaN(this.rubric.id)) {
        this.rubric.attachments.push(file.id);
        this.$store.dispatch(names.PATCH_OBSERVATION_INSTRUMENT, {
          observation_instrument_id: this.rubric.id,
          item: {
            attachments: this.rubric.attachments,
          },
        });
      }
    },
    createRubric() {
      this.$store
        .dispatch(names.POST_OBSERVATION_INSTRUMENT, this.rubric)
        .then((response) => {
          this.rubric = response;
          this.$emit("created", response);
        });
    },
    updateRubric() {
      this.$store
        .dispatch(names.UPDATE_OBSERVATION_INSTRUMENT, this.rubric)
        .then((response) => {
          this.rubric = response;
          this.$emit("updated", this.rubric);
        });
    },
    deleteRubric() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar la ${this.instrumentType.value}?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_OBSERVATION_INSTRUMENT, this.rubric.id)
            .then(() => {
              this.$emit("deleted", this.rubric);
            });
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>